
import { Vue, Component } from 'vue-property-decorator';
import { clone, omit, pick } from 'ramda';

import { Auth } from '@/store/modules';
import { User } from '@/store/auth';

import Terms from '@/components/Terms.vue';
import ImageUploadDialog from '@/components/ImageUploadDialog.vue';

@Component({
  name: "EditPrivateProfile",
  components: {
    Terms,
    ImageUploadDialog
  }
})
export default class extends Vue {
  requiredRule = (v: any) => !!v || 'This field is required';
  // eslint-disable-next-line no-control-regex
  emailRule = (v: string) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || "Please use correct email address";

  loading = false;
  error = '';

  loadingSave = false;
  errorSave = '';

  saved = false;
  termsAccepted = false;
  
  model: Partial<User> = {
    details: {},
  };

  avatarUrl = '';
  originalRole = 'student';

  acceptedTermsConditions: boolean = Auth.termsConditions || false;

  get userId() {
    return (Auth.user as User).id;
  }

  get isEdit() {
    return this.model && this.model.details && this.model.details.onboardingFinished;
  }

  get saveButtonText() {
    if (!this.model || !this.model.details) {
      return '';
    }

    return 'Save'
  }

  async refresh() {
    this.loading = true;
    try {
      const user = await Auth.getSelfInformation();
      this.model = clone(user);
      this.originalRole = (user as User).role;
      
      if (!this.model.details) {
        this.model.details = {};
      }

      this.avatarUrl = this.model.avatarUrl || '';
      
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  mounted() {
    this.refresh();
  }

  async save() {
    this.loadingSave = true;
    try {
      if(!(this.$refs as any).form.validate()) {
        throw new Error('Please correct highlighted problems');
      }

      if (!this.acceptedTermsConditions) {
        (this.$refs as any).Terms.open();
        this.loadingSave = false;
        return;
      }

      const toUpdateDetails = omit(['social_account'], this.model.details);
      const toUpdate: Partial<User> = pick(['email', 'name', 'role'], this.model);
      toUpdate.details = toUpdateDetails;
      toUpdate.avatarUrl = this.avatarUrl;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      toUpdate.details!.onboardingFinished = true;
      const updated = await Auth.updateAccount(toUpdate);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.model.details!.onboardingFinished = true;
      this.$emit('save', updated);
      this.saved = true;

      if (this.isEdit && this.model.role === 'teacher' && this.originalRole === 'student') {
        this.$router.push('/public-account');
      } else {
        this.$router.push('/');
      }
    } catch (err) {
      this.errorSave = err.message;
    }
    this.loadingSave = false;
  }

  onTermsAccept(values: any) {
    const { termsConditions, privacyPolicy, marketing } = values;

    if (!this.model.details) {
      this.model.details = {};
    }

    this.model.details.termsConditions = termsConditions;
    this.model.details.privacyPolicy = privacyPolicy;
    this.model.details.marketing = marketing;
    this.termsAccepted = true;
    this.acceptedTermsConditions = true;
    console.log(this.acceptedTermsConditions);
    this.save();
  }
}
