
import { Vue, Component } from 'vue-property-decorator';
import TermsOfService from './TermsOfService.vue';
import PrivacyPolicy from './PrivacyPolicy.vue';

@Component({
  name: "EditPrivateProfile",
  components: {
    TermsOfService,
    PrivacyPolicy
  }
})
export default class extends Vue {
  dialog = false;
  terms = true;
  policy = false;

  termsConditions = false;
  marketing = false;

  changeTab() {
    this.terms = !this.terms;
    this.policy = !this.policy;
  }

  get enableAccept() {
    return this.termsConditions;
  }

  open() {
    this.dialog = true;
  }

  accept() {
    this.$emit('accept', {
      termsConditions: this.termsConditions,
      privacyPolicy: this.termsConditions,
      marketing: this.marketing
    });
    this.dialog = false;
  }
}
